/* eslint-disable react/no-multi-comp */
import React, { Component, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'api/data-provider';
import Bem from 'bemHelper';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import get from 'lodash/get';
import each from 'lodash/groupBy';
import Button from 'components/button';
import { iconTypes } from 'components/icon';
import Link from 'components/link';

import ValidationState from './validationState';
import Highlighter from './Highlighter';
import AbstractInput from './abstractInput';
import { VALIDATION_VALID } from './form-consts';

import 'styles/base/containers/form/_input-autocomplete.scss';

/**
 * = AutocompleteInput Component =
 *
 *  How to use:
 *
 *  <AutocompleteInput
 *    dataBind="field_string"
 *    maxHeightDD={360}
 *    renderer={this.renderer}
 *    avatarRenderer={this.avatarRenderer}
 *    schema={{
 *      class: 'RuleString',
 *      label: 'Поиск по id темы (точное совпадение)',
 *      placeholder: desktop ? `\ue900 ${placeholder}` : '\ue900 Поиск по сайту'
 *    }}
 *    url="admin/crm/customers/tree-list-customers"
 *    onChange={this.onInputChange}
 *    value={this.state.searchValue}
 *    minLength={0}
 *    dictValue="title"
 *    dictKey="id"
 *    queryWord="search"
 *    onSelectItem={this.onSelect}
 *    bindOnlyTips={false}
 *    queryParams={{
 *      center: this.state.addressMapCenter,
 *      bbox: this.state.addressMapBbox
 *    }}
 *    canSetStringValueByProps
 *    bindNumber
 *    noError
 *  />
 */

const KEY_UP = 'ArrowUp';
const KEY_DOWN = 'ArrowDown';
const KEY_ENTER = 'Enter';
const KEY_ESC = 'Escape';

const bemClassesInput = new Bem('input');

class AutocompleteInput extends Component {
  static defaultProps = {
    dictKey: 'id',
    dictValue: 'value',
    minLength: 3,
    maxHeightDD: 360,
    bindOnlyTips: true,
    canSetStringValueByProps: false,
    bindNumber: false,
  };

  static propTypes = {
    ...AbstractInput.propTypes,
    className: PropTypes.string,
    renderer: PropTypes.func,
    avatarRenderer: PropTypes.func,
    url: PropTypes.string.isRequired,
    minLength: PropTypes.number,
    maxHeightDD: PropTypes.number,
    queryWord: PropTypes.string.isRequired,
    queryParams: PropTypes.shape({}),
    onSelectItem: PropTypes.func,
    onClear: PropTypes.func,
    noError: PropTypes.bool,
    bindNumber: PropTypes.bool,
    bindOnlyTips: PropTypes.bool,
    canSetStringValueByProps: PropTypes.bool,
    validationState: PropTypes.shape({
      state: PropTypes.string,
      messages: PropTypes.array
    }),
    linkBuilder: PropTypes.func,
    linkBuilderProps: PropTypes.shape({}),
    autoFocus: PropTypes.bool,
    // Create groups by this field (can be path for "get" function)
    groupBy: PropTypes.string,
    groupByRenderer: PropTypes.func,
    onLoad: PropTypes.func
  };

  constructor(...args) {
    super(...args);
    this.renderDataTips = this.renderDataTips.bind(this);
    this.performValidation = this.performValidation.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.dataUpdate = this.dataUpdate.bind(this);
    this.onSelectWrap = this.onSelectWrap.bind(this);
    this.createQuery = this.createQuery.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetAllData = this.resetAllData.bind(this);
    this.onInputLabelUpdate = this.onInputLabelUpdate.bind(this);
    this.getListInstance = this.getListInstance.bind(this);
    this.getInputInstance = this.getInputInstance.bind(this);
    this.onLoadDataProvider = this.onLoadDataProvider.bind(this);
    this.onErrorDataProvider = this.onErrorDataProvider.bind(this);

    this.listRef = null;
    this.inputRef = null;
    this.dataAutocomplete = [];


  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.value, nextProps.value) ||
      !isEqual(this.state, nextState) ||
      !isEqual(this.props.dictKey, nextProps.dictKey) ||
      !isEqual(this.props.validationState, nextProps.validationState) ||
      !isEqual(this.props.onValidation, nextProps.onValidation) ||
      !isEqual(this.props.canSetStringValueByProps, nextProps.canSetStringValueByProps) ||
      !isEqual(this.props.bindOnlyTips, nextProps.bindOnlyTips) ||
      !isEqual(this.props.bindNumber, nextProps.bindNumber) ||
      !isEqual(this.props.autoFocus, nextProps.autoFocus) ||
      !isEqual(this.props.schema.placeholder, nextProps.schema.placeholder)
      ;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      isNumber(this.props.value)
    ) {
      const found = find(this.dataAutocomplete, [this.props.dictKey, this.props.value]) || {};
      this.inputRef.safeSetState({
        val: found[this.props.dictValue] || '',
        searchParams: found[this.props.dictValue] || '',
        showTips: false
      });
    }
    //TODO: закоментировал потому что убирает первую букву при повторном поиске через автокомплит
    // if (this.props.value !== prevProps.value && this.props.value === null) {
    //   this.inputRef.safeSetState({
    //     val: '',
    //   });
    // }
  }

  onChange(val) {
    this.props.onChange(val);
  }

  onLoadDataProvider(data) {
    if (this.props.onLoad) this.props.onLoad(data);
    this.dataAutocomplete = data;
  }

  onErrorDataProvider() {
    this.dataAutocomplete = [];
  }

  onKeyDown(event, updateInput, showTips) {
    switch (event.key) {
      case KEY_UP:
        this.handleCommonKeyAction(event, updateInput, showTips);
        break;

      case KEY_DOWN:
        this.handleCommonKeyAction(event, updateInput, showTips);
        break;

      case KEY_ENTER:
        this.handleCommonKeyAction(event, updateInput, showTips);
        break;

      case KEY_ESC:
        event.preventDefault();
        updateInput({
          showTips: false
        });
        break;
      default:
      // do nothing
    }
  }

  onSelectWrap(func, updateLabel) {
    const { dictKey, dictValue } = this.props;
    return (item) => {
      const itemValue = item[dictValue] || null;
      const itemKey = item[dictKey] || null;
      updateLabel({ val: itemValue, searchParams: itemValue, showTips: false });

      func(itemValue, itemKey, item);
    };
  }

  onInputLabelUpdate(updateLabel) {
    const { dictKey, dictValue, value } = this.props;
    return (data) => {
      const found = find(data, { [dictKey]: value });
      if (found) {
        updateLabel({
          val: found[dictValue],
          showTips: false,
          avatar: found.avatar
        });
      }
    };
  }

  getInputInstance(ref) {
    this.inputRef = ref;
  }

  getListInstance(ref) {
    this.listRef = ref;
  }

  setNewValue() {
    if (
      (this.props.canSetStringValueByProps && typeof this.props.value === 'string')
      || this.props.bindNumber
    ) return this.props.value;

    return null;
  }

  resetAllData() {
    if (this.inputRef) this.inputRef.resetAllData();
  }

  handleCommonKeyAction(event, updateInput, showTips) {
    event.preventDefault();
    if (!showTips && this.dataAutocomplete.length >= 1) {
      updateInput({ showTips: true });
    } else {
      this.listRef.onKeyDown(event);
    }
  }

  performDataUpdate(value, item) {
    if (typeof this.props.onDataUpdateNeeded === 'function') {
      this.props.onDataUpdateNeeded(value, item);
    }
  }

  performValidation(value = this.props.value) {
    if (typeof this.props.onValidation === 'function') {
      this.props.onValidation(value);
    }
  }

  handleSelect(value, key, item) {
    this.props.onChange(key, item);
    this.performValidation(key);
    if (this.props.onSelectItem) this.props.onSelectItem(item);
    this.performDataUpdate(value, item);
  }

  dataUpdate(value) {
    this.performDataUpdate(value);
  }

  createQuery(searchParams) {
    if (
      !searchParams && this.props.bindNumber && isNumber(this.props.value)
    ) return { [this.props.dictKey]: this.props.value };

    if (searchParams) return { [this.props.queryWord]: searchParams };

    return null;
  }

  renderDataTips({ queryToApi, searchParams, showTips, updateLabel, onMouseDown }) {
    return (
      <AutocompleteProvider
        url={this.props.url}
        queryParams={this.props.queryParams}
        queryToApi={queryToApi}
        onLoad={this.onLoadDataProvider}
        onError={this.onErrorDataProvider}
        groupBy={this.props.groupBy}
      >
        <AutocompleteList
          ref={this.getListInstance}
          dictValue={this.props.dictValue}
          dictKey={this.props.dictKey}
          dataBindToDataAutocomplete={this.props.dataBind}
          value={this.props.value}
          val={searchParams}
          onMouseDown={onMouseDown}
          onClick={this.onSelectWrap(this.handleSelect, updateLabel)}
          updateInputLabel={this.onInputLabelUpdate(updateLabel)}
          dataUpdate={this.dataUpdate}
          showTips={showTips}
          maxHeightDD={this.props.maxHeightDD}
          renderer={this.props.renderer}
          linkBuilder={this.props.linkBuilder}
          linkBuilderProps={this.props.linkBuilderProps}
          groupBy={this.props.groupBy}
          groupByRenderer={this.props.groupByRenderer}
        />
      </AutocompleteProvider>
    );
  }

  render() {
    const validationState = this.props.validationState
      ? this.props.validationState.state
      : VALIDATION_VALID;

    const {
      schema = {}, onClear,
      className, top, left, white, noError, autoFocus
    } = this.props;
    const required = schema.required;
    const mods = { required, top, left, white };

    return (
      <div {...bemClassesInput(null, mods, className)}>
        <div {...bemClassesInput('inputWrapper')}>
          <div {...bemClassesInput('inputContainer')} data-test="inputContainer">
            <AutocompleteSearchInput
              ref={this.getInputInstance}
              schema={schema}
              dataBind={this.props.dataBind}
              dictKey={this.props.dictKey}
              type={this.props.type || 'text'}
              name={this.props.name && this.props.name}
              minLength={this.props.minLength}
              maxHeightDD={this.props.maxHeightDD}
              onChange={this.onChange}
              onClear={onClear}
              onKeyDown={this.onKeyDown}
              performValidation={this.performValidation}
              createQuery={this.createQuery}
              avatarRenderer={this.props.avatarRenderer}
              bindOnlyTips={this.props.bindOnlyTips}
              bindNumber={this.props.bindNumber}
              newValue={this.setNewValue()}
              validationState={validationState}
              autoFocus={autoFocus}
              children={this.renderDataTips}
            />
            {schema.label && (
              <div {...bemClassesInput('labelContainer')}>
                <span {...bemClassesInput('text', 'label')}>{schema.label}</span>
                {required && <Fragment>&nbsp;<span {...bemClassesInput('text', 'asterisk')}>*</span></Fragment>}
              </div>
            )}
            <span {...bemClassesInput('bottomLine')} />
          </div>
          {!noError &&
          <div {...bemClassesInput('errorWrapper')}>
            {!this.props.disableValidationState &&
            <ValidationState
              validationState={this.props.validationState}
            />
            }
          </div>
          }
        </div>
      </div>
    );
  }
}

class AutocompleteSearchInput extends PureComponent {
  static propTypes = {
    newValue: PropTypes.any, // eslint-disable-line
    schema: PropTypes.shape({}),
    dataBind: PropTypes.string,
    dictKey: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    minLength: PropTypes.number,
    maxHeightDD: PropTypes.number,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onKeyDown: PropTypes.func,
    performValidation: PropTypes.func,
    createQuery: PropTypes.func,
    avatarRenderer: PropTypes.func,
    children: PropTypes.func,
    bindOnlyTips: PropTypes.bool,
    bindNumber: PropTypes.bool,
    validationState: PropTypes.string,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.bindNumber && props.newValue !== null && props.newValue !== state.val) {
      return {
        val: props.newValue,
        searchParams: props.newValue,
        showTips: false
      };
    }
    return null;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.safeSetState = this.safeSetState.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.updateParams = debounce(this.updateParams.bind(this), 500);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.resetAllData = this.resetAllData.bind(this, true);

    this.state = {
      val: '',
      searchParams: '',
      showTips: false,
      avatar: null,
    };
    this.inputRef = null;
    this.unmounted = false;
    this.ignoreBlur = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocus) this.inputRef.focus();
    if (prevProps.newValue !== this.props.newValue) {
      if (!this.props.newValue) return this.resetAllData();
      this.safeSetState({
        val: this.props.newValue,
        searchParams: this.props.newValue,
        showTips: false
      });
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  onKeyDown(event) {
    this.props.onKeyDown(event, this.safeSetState, this.state.showTips);
  }

  onChange(event) {
    const val = event.target.value;
    const valIsLonger = val.length > this.props.minLength;
    this.props.onChange(!this.props.bindOnlyTips ? val : null);

    this.safeSetState({
      val,
      direction: this.calcDirection(),
      searchParams: valIsLonger ? this.state.searchParams : ''
    });

    if (valIsLonger) {
      this.updateParams({
        searchParams: val,
        showTips: true,
      });
    }
  }

  onMouseDown() {
    this.ignoreBlur = true;
  }

  safeSetState(state) {
    if (!this.unmounted && !isEqual(state, this.state)) this.setState(state);
  }

  calcDirection() {
    if (!this.inputRef) return 'bottom';

    let windowHeight = 0;
    if (window.innerWidth) {
      windowHeight = window.innerHeight;
    } else if (document.documentElement && document.documentElement.clientWidth !== 0) {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.getElementsByTagName('body')[0].clientHeight;
    }
    const inputRect = this.inputRef.getBoundingClientRect();
    const toBottom = windowHeight - inputRect.top - inputRect.height;

    if (
      (toBottom < this.props.maxHeightDD) &&
      (inputRect.top > this.props.maxHeightDD)
    ) {
      return 'top';
    }
    return 'bottom';
  }

  updateParams(state) {
    this.safeSetState(state);
  }

  handleFocus() {
    if (!this.state.searchParams) return;

    this.safeSetState({
      showTips: true,
      direction: this.calcDirection()
    });
  }

  handleBlur() {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    if (this.props.performValidation) this.props.performValidation();
    this.safeSetState({ showTips: false });
  }

  resetAllData(event, focus = false) {
    this.props.onChange(null);
    if (typeof this.props.onClear === 'function') this.props.onClear();
    this.safeSetState({
      val: '',
      showTips: false,
      searchParams: '',
      avatar: null
    });

    if (focus) this.inputRef.focus();
  }

  renderProvider() {
    const queryToApi = this.props.createQuery(this.state.searchParams);
    if (queryToApi) {
      return (
        <div
          {...bemClassesInput('autocompleteContent', {
            topDD: this.state.direction === 'top'
          })}
        >
          <div {...bemClassesInput('main')}>
            {this.props.children({
              searchParams: this.state.searchParams,
              showTips: this.state.showTips,
              queryToApi,
              updateLabel: this.safeSetState,
              onMouseDown: this.onMouseDown,
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { schema = {}, validationState, avatarRenderer } = this.props;

    return (
      <Fragment>
        {avatarRenderer && avatarRenderer(this.state.avatarRenderer)}
        <input
          data-test={this.props.dataBind ? `${this.props.dataBind}_autocomplete_input` : 'autocomplete_input'}
          ref={input => this.inputRef = input}
          {...bemClassesInput('input', {
            error: validationState !== VALIDATION_VALID
          })}
          value={this.state.val}
          name={this.props.name && this.props.name}
          type={this.props.type || 'text'}
          placeholder={schema.placeholder || ''}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          autoComplete="off"
        />
        {this.state.val && this.state.val.length > 0 && (
          <Button
            {...bemClassesInput('btn', 'clear')}
            data-test={this.props.dataBind
              ? `${this.props.dataBind}_autocomplete_clean_button` : 'autocomplete_clean_button'}
            onClick={this.resetAllData}
            iconType={iconTypes.clear}
            tabIndex="-1"
            noPadding
          />
        )}
        {this.renderProvider()}
      </Fragment>
    );
  }
}

class AutocompleteProvider extends PureComponent {
  static propTypes = {
    url: PropTypes.string,
    queryParams: PropTypes.shape({}),
    queryToApi: PropTypes.shape({}),
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    children: PropTypes.node,
    groupBy: PropTypes.string
  };

  groupByModifier = (data) => {
    if (!this.props.groupBy) {
      return data;
    }

    const newData = sortBy(map(data, (val, key) => {
      return { ...val, $key: key };
    }), val => ([get(val, this.props.groupBy), val.$key]));

    return newData;
  };

  render() {
    const { queryToApi = {}, queryParams = {} } = this.props;

    return (
      <DataProvider
        url={this.props.url}
        query={{
          ...queryToApi,
          ...queryParams
        }}
        queryJson
        dataModifier={this.groupByModifier}
        injectPropName="autocompleteData"
        onLoad={this.props.onLoad}
        onError={this.props.onError}
        children={this.props.children}
      />
    );
  }
}

const bemClassesGroup = new Bem('groupAutocomplete');

class AutocompleteList extends PureComponent {
  static propTypes = {
    autocompleteData: PropTypes.any, // eslint-disable-line
    dictKey: PropTypes.string,
    dictValue: PropTypes.string,
    dataBindToDataAutocomplete: PropTypes.string,
    value: PropTypes.any, // eslint-disable-line
    val: PropTypes.string,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    updateInputLabel: PropTypes.func,
    showTips: PropTypes.bool,
    maxHeightDD: PropTypes.number,
    renderer: PropTypes.func,
    dataUpdate: PropTypes.func,
    linkBuilder: PropTypes.func,
    linkBuilderProps: PropTypes.shape({}),
    groupBy: PropTypes.string,
    groupByRenderer: PropTypes.func
  };

  static getDerivedStateFromProps(props, state) {
    const { autocompleteData = [] } = props;
    const nextPropsLength = autocompleteData.length;
    const newState = {
      dataLength: nextPropsLength
    };

    if (state.dataLength !== nextPropsLength) {
      if (nextPropsLength === 1) {
        newState.selected = 0;
      } else if (state.selected !== null) {
        newState.selected = null;
      }
    }
    return newState;
  }

  constructor(...args) {
    super(...args);
    this.renderItems = this.renderItems.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setIgnoreMouseEnter = this.setIgnoreMouseEnter.bind(this);
    this.setIgnoreScroll = this.setIgnoreScroll.bind(this);
    this.highlightItemFromMouse = this.highlightItemFromMouse.bind(this);

    this.state = {
      selected: null
    };
  }

  componentDidMount() {
    this.props.updateInputLabel(this.props.autocompleteData);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.autocompleteData, prevProps.autocompleteData)) {
      this.props.updateInputLabel(this.props.autocompleteData);
    }

    if (
      this.state.selected !== null && this.props.showTips &&
      this.props.autocompleteData.length > 0
    ) {
      if (this.ignoreScroll) return;
      if (!this.autocompleteHoverElement || !this.activeElement) return;

      const autocompleteRect = this.autocompleteHoverElement.getBoundingClientRect();
      const activeRect = this.activeElement.getBoundingClientRect();
      const diffBottom = autocompleteRect.bottom - activeRect.bottom;
      if (diffBottom < 0) {
        this.setIgnoreMouseEnter(true);
        this.autocompleteHoverElement.scrollTop =
          Math.abs(diffBottom) + this.autocompleteHoverElement.scrollTop;
      }

      const diffTop = autocompleteRect.top - activeRect.top;
      if (diffTop > 0) {
        this.setIgnoreMouseEnter(true);
        this.autocompleteHoverElement.scrollTop =
          this.autocompleteHoverElement.scrollTop - Math.abs(diffTop);
      }
    }
  }

  onKeyDown(event) {
    const dataAuto = this.props.autocompleteData;
    const maxIndex = dataAuto.length - 1;
    const sel = this.state.selected;

    switch (event.key) {
      case KEY_UP:
        if (this.ignoreScroll) this.setIgnoreScroll(false);
        this.setState({ selected: (sel === null || sel <= 0) ? maxIndex : sel - 1 });
        break;

      case KEY_DOWN:
        if (this.ignoreScroll) this.setIgnoreScroll(false);
        this.setState({ selected: (sel === null || sel + 1 > maxIndex) ? 0 : sel + 1 });
        break;

      case KEY_ENTER:
        if (sel !== null) this.handleSelect(dataAuto[sel]);
        if (sel === null) this.props.dataUpdate(this.props.value);
        break;

      default:
      // do nothing
    }
  }

  setIgnoreMouseEnter(ignore) {
    this.ignoreMouseEnter = ignore;
  }

  setIgnoreScroll(ignore) {
    this.ignoreScroll = ignore;
  }

  handleSelect(it) {
    if (this.props.onClick) this.props.onClick(it);
  }

  highlightItemFromMouse(it) {
    if (this.ignoreMouseEnter) return;

    this.setIgnoreScroll(true);
    const { dictKey } = this.props;
    const foundIndex = findIndex(this.props.autocompleteData, [dictKey, it[dictKey]]);
    this.setState({ selected: foundIndex !== -1 ? foundIndex : 0 });
  }

  renderItems(it, index) {
    const {
      val, dictValue, dictKey, renderer,
      linkBuilder, linkBuilderProps, dataBindToDataAutocomplete
    } = this.props;
    const { selected } = this.state;
    const active = index === selected;

    return (
      <AutocompleteItem
        key={it[dictKey]}
        value={it[dictValue] ? it[dictValue] : false}
        active={active}
        it={it}
        val={val}
        itemRef={activeBtn => active ? this.activeElement = activeBtn : null}
        onMouseDown={this.props.onMouseDown}
        onMouseEnter={this.highlightItemFromMouse}
        onMouseMove={this.setIgnoreMouseEnter}
        onClick={this.props.onClick}
        renderer={renderer}
        linkBuilder={linkBuilder}
        linkBuilderProps={linkBuilderProps}
        dataTest={dataBindToDataAutocomplete}
      />
    );
  }

  renderPlain(data) {
    return map(data, this.renderItems);
  }

  renderGroups(data, groupBy) {
    let lastGrpBy = null;
    return map(data, (item, iKey) => {
      const grp = get(item, groupBy) !== lastGrpBy ? (
        <div {...bemClassesGroup()} children={this.props.groupByRenderer(item)} />
      ) : null;
      lastGrpBy = get(item, groupBy);
      return (
        <Fragment key={iKey}>
          {grp}
          {this.renderItems(item, iKey)}
        </Fragment>
      );
    });
  }

  render() {
    const { autocompleteData, dataBindToDataAutocomplete, groupBy, showTips } = this.props;
    return (
      <div
        className="mainContent"
        data-test={`${dataBindToDataAutocomplete}_autocomplete_tips`}
        style={{ maxHeight: this.props.maxHeightDD }}
        ref={hoverRef => this.autocompleteHoverElement = hoverRef}
      >
        {(showTips && autocompleteData && autocompleteData.length > 0) &&
          (
            groupBy ?
              this.renderGroups(autocompleteData, groupBy) :
              this.renderPlain(autocompleteData)
          )
        }
      </div>
    );
  }
}

const bemClassesItemBtn = new Bem('buttonAutocomplete');
const bemClassesItem = new Bem('itemAutocomplete');

class AutocompleteItem extends Component {
  static propTypes = {
    value: PropTypes.any, // eslint-disable-line
    renderer: PropTypes.func,
    active: PropTypes.bool,
    it: PropTypes.shape({}),
    val: PropTypes.string,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onClick: PropTypes.func,
    itemRef: PropTypes.func,
    linkBuilder: PropTypes.func,
    linkBuilderProps: PropTypes.shape({}),
    dataTest: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.active !== nextProps.active ||
      this.props.val !== nextProps.val ||
      this.props.value !== nextProps.value ||
      !isEqual(this.props.it, nextProps.it)
    );
  }

  onMouseDown() {
    if (this.props.onMouseDown) this.props.onMouseDown();
  }

  handleMouseEnter() {
    if (this.props.onMouseEnter) this.props.onMouseEnter(this.props.it);
  }

  handleMouseMove() {
    if (this.props.onMouseMove) this.props.onMouseMove(false);
  }

  handleSelect() {
    if (this.props.onClick) this.props.onClick(this.props.it);
  }

  render() {
    const {
      value, active, val, renderer, it, itemRef,
      linkBuilder, linkBuilderProps = {}
    } = this.props;

    let ItemTag = Button;
    const mouseEvents = {
      onMouseDown: this.onMouseDown,
      onMouseEnter: this.handleMouseEnter,
      onMouseMove: this.handleMouseMove,
    };

    let props = {
      className: bemClassesItemBtn({ modifiers: { active } }).className,
      onClick: this.handleSelect,
    };
    if (linkBuilder) {
      ItemTag = Link;
      props.builder = linkBuilder;
      props.builderProps = { ...it, ...linkBuilderProps };
      props.addEvents = { ...mouseEvents };
      props.linkRef = itemRef;
    } else {
      props = {
        ...props,
        ...mouseEvents,
        btnRef: itemRef,
        customRenderer: true
      };
    }

    return (
      <ItemTag
        data-test={`${this.props.dataTest}_autocomplete_btn`}
        {...props}
      >
        {!renderer ?
          <div {...bemClassesItem()}>
            <div {...bemClassesItem('сontent')}>
              <span {...bemClassesItem('text')}>
                <Highlighter
                  highlightStyle={{ fontWeight: 'normal', backgroundColor: 'transparent' }}
                  searchWords={val.replace(/,|\s+|\(|\)/g, ' ').split(' ')}
                  textToHighlight={value}
                />
              </span>
            </div>
          </div> :
          renderer(it)
        }
      </ItemTag>
    );
  }
}

export default AutocompleteInput;
